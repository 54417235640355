.App{
    width: 100%;
    height: 100%;
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
}
.clients-card{
    padding-bottom: 0!important;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    height: 30%;
}
.nav-item{
    cursor: pointer;
}

.clients-title{
    margin-top: 0.6rem;
}

.table-container{
    overflow: scroll;
}

.tr-client{
    cursor: pointer;
}

.tr-client:hover{
    background-color: #D8D8D8;
}

.tr-client:active{
    background-color: gray;
}

.addNewClient{
    position: absolute;
    top: 1%;
    right: 1%;
    z-index: 1000;
}

.addNewSKP{
    position: absolute;
    top: 1%;
    right: 10%;
    z-index: 1000;
}

.saveButton{
    width: 100%;
}

.modules-card-button{
    position: absolute;
    top: 0;
    right: 0;
}

.marginBottom{
    margin-bottom: 0.7rem;
}

.csv-reader-input{
    height: 20px;
}

.csv-label{
    font-size: 2rem;
}

.filename{
    font-size: 0.70rem;
    transform: translateY(30px);
}

.loginForm{
    width: 40%;
    margin: auto;
    margin-top: 10%;
}